import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0aa00430 = () => interopDefault(import('../pages/acknowledgement.vue' /* webpackChunkName: "pages/acknowledgement" */))
const _f83677d2 = () => interopDefault(import('../pages/active-queues.vue' /* webpackChunkName: "pages/active-queues" */))
const _bf2f4bf6 = () => interopDefault(import('../pages/customer-feedback-layout.vue' /* webpackChunkName: "pages/customer-feedback-layout" */))
const _2fb6ab9b = () => interopDefault(import('../pages/customer-feedback-one.vue' /* webpackChunkName: "pages/customer-feedback-one" */))
const _4400b577 = () => interopDefault(import('../pages/ended-queues.vue' /* webpackChunkName: "pages/ended-queues" */))
const _93397098 = () => interopDefault(import('../pages/feedback-acknowledgement.vue' /* webpackChunkName: "pages/feedback-acknowledgement" */))
const _210e8d52 = () => interopDefault(import('../pages/help-function.vue' /* webpackChunkName: "pages/help-function" */))
const _643ecc0c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _2fafc162 = () => interopDefault(import('../pages/missed-queues.vue' /* webpackChunkName: "pages/missed-queues" */))
const _1ecdf8f4 = () => interopDefault(import('../pages/queue-details.vue' /* webpackChunkName: "pages/queue-details" */))
const _481c0f49 = () => interopDefault(import('../pages/rate-our-service.vue' /* webpackChunkName: "pages/rate-our-service" */))
const _c84e7796 = () => interopDefault(import('../pages/search-queues.vue' /* webpackChunkName: "pages/search-queues" */))
const _f45b4a2c = () => interopDefault(import('../pages/service-dashboard.vue' /* webpackChunkName: "pages/service-dashboard" */))
const _7ba40b5a = () => interopDefault(import('../pages/stored-queues.vue' /* webpackChunkName: "pages/stored-queues" */))
const _6e943da5 = () => interopDefault(import('../pages/toggle-call.vue' /* webpackChunkName: "pages/toggle-call" */))
const _160f8440 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/acknowledgement",
    component: _0aa00430,
    name: "acknowledgement"
  }, {
    path: "/active-queues",
    component: _f83677d2,
    name: "active-queues"
  }, {
    path: "/customer-feedback-layout",
    component: _bf2f4bf6,
    name: "customer-feedback-layout"
  }, {
    path: "/customer-feedback-one",
    component: _2fb6ab9b,
    name: "customer-feedback-one"
  }, {
    path: "/ended-queues",
    component: _4400b577,
    name: "ended-queues"
  }, {
    path: "/feedback-acknowledgement",
    component: _93397098,
    name: "feedback-acknowledgement"
  }, {
    path: "/help-function",
    component: _210e8d52,
    name: "help-function"
  }, {
    path: "/logout",
    component: _643ecc0c,
    name: "logout"
  }, {
    path: "/missed-queues",
    component: _2fafc162,
    name: "missed-queues"
  }, {
    path: "/queue-details",
    component: _1ecdf8f4,
    name: "queue-details"
  }, {
    path: "/rate-our-service",
    component: _481c0f49,
    name: "rate-our-service"
  }, {
    path: "/search-queues",
    component: _c84e7796,
    name: "search-queues"
  }, {
    path: "/service-dashboard",
    component: _f45b4a2c,
    name: "service-dashboard"
  }, {
    path: "/stored-queues",
    component: _7ba40b5a,
    name: "stored-queues"
  }, {
    path: "/toggle-call",
    component: _6e943da5,
    name: "toggle-call"
  }, {
    path: "/",
    component: _160f8440,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
