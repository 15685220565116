
import { sanitizeUrl } from '@braintree/sanitize-url';
export default {
  name: "AppSubmenu",
  props: {
    items: Array,
    root: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      activeIndex: null,
      defaultUrl: '#'
    };
  },
  methods: {
    sanitizeMenuUrl(url) {
			const sanitizedUrl = sanitizeUrl(url);
			return sanitizedUrl;
		},
    onMenuItemClick: function (event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (!item.to && !item.url) {
        event.preventDefault();
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
      }

      this.activeIndex = index === this.activeIndex ? null : index;

      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    },
  },
  components: {
    AppSubmenu: this,
  },
};
