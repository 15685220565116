export const initialState = () => ({
  refCount: 0,
  isLoading: false,
  baseDomain: "",
  setIsActiveTab: true,
  setIsDetailTab: false,
  customerFeedback: { queueId: "", phoneNumber: "" },
  divisionInfo: { divisionName: "", servicePoint: "" },
  activeQueueCount: 0,
  servingQueue: null,
  viewingQueue: {},
  activeQueueTabIndex: 3,
  pollingInterval: 30000,
  inActiveTimeDuration: 1800000,
  searchInfo: {
    searchDetails: {
      queueNumber: "",
      service: [],
      serviceCounter: [],
      queueStatus: [],
    },
    firstRowDisplay: 0,
  },
  historyRouteQueueDetail: "",
  userContext: {
  },
  waitingQueuesCount: 0,
});

export const state = () => {
  return initialState();
}

export const mutations = {
  loading(state, isLoading) {
    if (isLoading) {
      state.refCount++;
      state.isLoading = true;
    } else if (state.refCount > 0) {
      state.refCount--;
      state.isLoading = state.refCount > 0;
    }
  },
  setloading(state,isLoading){
    state.isLoading = isLoading;
  } ,
  setCustomerFeedback(state, customerFeedback) {
    state.customerFeedback = customerFeedback;
  },
  setDivision(state, divisionInfo) {
    state.divisionInfo = divisionInfo;
  },
  setPollingTime(state, interval) {
    state.pollingInterval = interval;
  },
  setActivesCount(state, activeQueueCount) {
    state.activeQueueCount = activeQueueCount;
  },
  setServingQueue(state, servingQueue) {
    state.servingQueue = servingQueue;
  },
  setViewingQueue(state, viewingQueue) {
    state.viewingQueue = viewingQueue;
  },
  setIsActiveTab(state, setIsActiveTab) {
    state.setIsActiveTab = setIsActiveTab;
  },
  setIsDetailTab(state, setIsDetailTab) {
    state.setIsDetailTab = setIsDetailTab;
  },
  setActiveQueueTabIndex(state, activeQueueTabIndex) {
    state.activeQueueTabIndex = activeQueueTabIndex;
  },
  setUserContext(state, userContext) {
    state.userContext = userContext;
  },
  setSearchInfo(state, searchInfo) {
    state.searchInfo = searchInfo;
  },
  resetSearch(state) {
    const s = initialState();
    state.searchInfo = s.searchInfo;
    state.searchAppInfo = s.searchAppInfo;
  },
  setHistoryRouteQueueDetail(state, historyRouteQueueDetail) {
    state.historyRouteQueueDetail = historyRouteQueueDetail;
  },
  reset(state) {
    const baseDomain = state.baseDomain;
    const s = initialState();
    Object.assign(state, s);
    state.baseDomain = baseDomain;
  },
  setWaitingQueuesCount(state, waitingQueuesCount) {
    state.waitingQueuesCount = waitingQueuesCount;
  },
};
export const actions = {
  setloading({ commit }, isLoading) {
    commit("setloading", isLoading);

  },
  setCustomerFeedback({ commit }, customerFeedback) {
    commit("setCustomerFeedback", customerFeedback);
  },
  setDivision({ commit }, divisionInfo) {
    commit("setDivision", divisionInfo);
  },
  setPollingTime({ commit }, interval) {
    commit("setPollingTime", interval);
  },
  setActivesCount({ commit }, activeQueueCount) {
    commit("setActivesCount", activeQueueCount);
  },
  setServingQueue({ commit }, servingQueue) {
    commit("setServingQueue", servingQueue);
  },
  setViewingQueue({ commit }, viewingQueue) {
    commit("setViewingQueue", viewingQueue);
  },
  setIsActiveTab({ commit }, setIsActiveTab) {
    commit("setIsActiveTab", setIsActiveTab);
  },
  setIsDetailTab({ commit }, setIsDetailTab) {
    commit("setIsDetailTab", setIsDetailTab);
  },
  setActiveQueueTabIndex({ commit }, activeQueueTabIndex) {
    commit("setActiveQueueTabIndex", activeQueueTabIndex);
  },
  setSearchInfo({ commit }, searchInfo) {
    commit("setSearchInfo", searchInfo);
  },
  setHistoryRouteQueueDetail({ commit }, historyRouteQueueDetail) {
    commit("setHistoryRouteQueueDetail", historyRouteQueueDetail);
  },
  logout({ commit }) {
    commit("reset");
  },
  resetSearch({ commit }) {
    commit("resetSearch");
  },
  reset({ commit }) {
    commit("reset");
  },
  setUserContext({ commit }, userContext) {
    commit("setUserContext", userContext);
  },
  setWaitingQueuesCount({ commit }, waitingQueuesCount) {
    commit("setWaitingQueuesCount", waitingQueuesCount);
  },
};
export const getters = {
  getCustomerFeedback(state) {
    return state.customerFeedback;
  },
  getDivison(state) {
    return state.divisionInfo;
  },
  getPollingInterval(state) {
    return state.pollingInterval;
  },
  getSearchInfo(state) {
    return state.searchInfo;
  },
  getHistoryRouteQueueDetail(state) {
    return state.historyRouteQueueDetail;
  },
  getUserContext(state) {
    return state.userContext;
  },
  displayName(state) {
    return state.userContext ? state.userContext.DisplayName : "";
  },
  moduleName(state) {
    return state.userContext ? state.userContext.ModuleName : "";
  },
  servingQueue(state) {
    return state.servingQueue;
  }, 
  viewingQueue(state) {
    return state.viewingQueue;
  },
  waitingQueuesCount(state) {
    return state.waitingQueuesCount;
  },

};
