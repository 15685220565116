import { QueuesManager } from "./QueuesManager";
import { WebsocketManager } from "./WebsocketManager";
import { AntiforgeryTokenManager } from "./AntiforgeryTokenManager";
 import { UserSessionManager } from "./user-session-manager";

export default (context, inject) => {
  const queuesManager = new QueuesManager(context);
  const websocketManager = new WebsocketManager(queuesManager);
  const antiforgeryTokenManager = new AntiforgeryTokenManager();
  const userSessionManager = new UserSessionManager(context, websocketManager);

  inject("websocketManager", websocketManager);
  inject("queuesManager", queuesManager);
  inject("antiforgeryTokenManager", antiforgeryTokenManager);
  inject("userSessionManager", userSessionManager);
};
