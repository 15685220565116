import { render, staticRenderFns } from "./AppLayout.vue?vue&type=template&id=818b1eb4&"
import script from "./AppLayout.vue?vue&type=script&lang=js&"
export * from "./AppLayout.vue?vue&type=script&lang=js&"
import style0 from "./AppLayout.vue?vue&type=style&index=0&id=818b1eb4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppProfile: require('/codebuild/output/src2352550267/src/Cloud-AQMS-ServicePoint/src/qengine-servicepoint-ui/components/AppLayout/AppProfile.vue').default,AppMenu: require('/codebuild/output/src2352550267/src/Cloud-AQMS-ServicePoint/src/qengine-servicepoint-ui/components/AppLayout/AppMenu.vue').default,AppFooter: require('/codebuild/output/src2352550267/src/Cloud-AQMS-ServicePoint/src/qengine-servicepoint-ui/components/AppLayout/AppFooter.vue').default})
