export class AntiforgeryTokenManager {
  constructor() {
    this.token = null;
  }
  setAntiForgeryToken(token) {
    this.token = token;
  }
  getAntiForgeryToken() {
    return this.token;
  }
}
