import Vue from 'vue';
import Vuelidate from 'vuelidate';
import "primevue/resources/themes/nova/theme.css";
import "primevue/resources/primevue.min.css";
import 'primeflex/primeflex.css';
import Dialog from 'primevue/dialog';
import ToastMessages from '@/plugins/toast.js';
import "@/external-plugins/dialog";
Vue.use(Vuelidate);
Vue.component('Dialog', Dialog);
Vue.mixin(ToastMessages);
Vue.directive('focus', focus);
   