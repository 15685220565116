export class QueuesManager {
  constructor(context) {
    this.$store = context.app.store;
    this.queues = {
      newAndServingQueues: [],
      missedQueues: [],
      endedQueues: [],
      transferredQueues: [],
      storedQueues: [],
    };
  }
  sortOnDateData(a, b) {
    if (a.QueueStatus === "Serving" || b.QueueStatus === "Serving") {
      return 0;
    }
    return (
      new Date(a.RegistrationTime).getTime() -
      new Date(b.RegistrationTime).getTime()
    );
  }
  onWebsocketReconnected() {
    console.log("Websocket Reconnected.");
  }
  onQueueEvent(eventName, data) {
    switch (eventName) {
      case "WaitingQList":
        this.setWaitingQList(data);
        break;
      case "StoredQList":
        this.setStoredQList(data);
        break;
      case "MissedQList":
        this.setMissedQList(data);
        break;
      case "EndedQList":
        this.setEndedQList(data);
        break;
      case "TransferredQList":
        this.setTransferredQList(data);
        break;
      case "NewQ":
        this.setNewQueue(data);
        break;
      case "CalledQ":
        this.setServingQueue(data);
        break;
      case "MissedQ":
        this.setMissedQueue(data);
        break;
      case "StoredQ":
        this.setStoredQueue(data);
        break;
      case "EndedQ":
        this.setEndedQueue(data);
        break;
      case "TransferredQ":
        this.setTransferredQueue(data);
        break;
    }
  }
  setEndedQueue(queue) {
    const index = this.queues.newAndServingQueues.findIndex(
      (x) => x.QueueId == queue.QueueId
    );
    if (index >= 0) {
      this.queues.newAndServingQueues.splice(index, 1);
    }
    this.queues.endedQueues.push(queue);
    this.$store.commit("setWaitingQueuesCount", this.getWaitingQueuesCount());
  }
  setTransferredQueue(queue) {
    const index = this.queues.newAndServingQueues.findIndex(
      (x) => x.QueueId == queue.QueueId && x.QueueStatus === "Serving"
    );
    if (index >= 0) {
      this.queues.newAndServingQueues.splice(index, 1);
    }
    this.queues.transferredQueues.push(queue);
    this.$store.commit("setWaitingQueuesCount", this.getWaitingQueuesCount());
  }
  setStoredQueue(queue) {
    const index = this.queues.newAndServingQueues.findIndex(
      (x) => x.QueueId == queue.QueueId
    );
    if (index >= 0) {
      this.queues.newAndServingQueues.splice(index, 1);
    }
    this.queues.storedQueues.push(queue);
    this.$store.commit("setWaitingQueuesCount", this.getWaitingQueuesCount());
  }
  setMissedQueue(queue) {
    const index = this.queues.newAndServingQueues.findIndex(
      (x) => x.QueueId == queue.QueueId
    );
    if (index >= 0) {
      this.queues.newAndServingQueues.splice(index, 1);
    }
    this.queues.missedQueues.push(queue);
    this.$store.commit("setWaitingQueuesCount", this.getWaitingQueuesCount());
  }
  setServingQueue(queue) {
    const index = this.queues.newAndServingQueues.findIndex(
      (x) => x.QueueId === queue.QueueId
    );
    if (index >= 0) {
      //Remove the queue from it's current position
      this.queues.newAndServingQueues.splice(index, 1);
    }

    const endedQueueIndex = this.queues.endedQueues.findIndex(
      (x) => x.QueueId === queue.QueueId
    );
    if (endedQueueIndex >= 0) {
      //Remove the queue from it's current position
      this.queues.endedQueues.splice(endedQueueIndex, 1);
    }

    const missedQueueIndex = this.queues.missedQueues.findIndex(
      (x) => x.QueueId === queue.QueueId
    );
    if (missedQueueIndex >= 0) {
      //Remove the queue from it's current position
      this.queues.missedQueues.splice(missedQueueIndex, 1);
    }

    const storedQueueIndex = this.queues.storedQueues.findIndex(
      (x) => x.QueueId === queue.QueueId
    );
    if (storedQueueIndex >= 0) {
      //Remove the queue from it's current position
      this.queues.storedQueues.splice(storedQueueIndex, 1);
    }

    this.addServingQueueOnTop(queue);
    queue.IsBlink = false;
    this.$store.commit("setWaitingQueuesCount", this.getWaitingQueuesCount());
    this.$store.commit("setServingQueue", Object.assign({}, queue));

   // this.setViewingQueue(queue);
  }
  addServingQueueOnTop(queue) {
    if (
      queue.ServiceCounterId ===
      this.$store.state.userContext.ServiceCounterId
    ) {
      this.queues.newAndServingQueues.splice(0, 0, queue);
      this.setViewingQueue(queue);
    }
  }
  setViewingQueue(queue) {
    if (queue) {
      // Check in newq list - update all queues with IsViewing false
      let selectedQueue = this.queues.newAndServingQueues.find(
        (x) => x.QueueId === queue.QueueId
      );
      if (selectedQueue) {
        this.queues.newAndServingQueues.forEach((q) => {
          q.IsViewing = false;
        });
      }
      // Set Viewing status based on Serving
      queue.IsViewing = !queue.IsServing;
    }
    this.$store.commit("setViewingQueue", Object.assign({}, queue));
  }
  setUnSelectAll(){
    this.queues.newAndServingQueues.forEach((q) => {
      q.IsViewing = false;
    });
  }
  setNewQueue(queue) {
    let index = 0;
    if (this.queues.newAndServingQueues.length === 0) {
      this.queues.newAndServingQueues.push(queue);
    } else {
      // if (queue.Priority) {
      //   index = this.queues.newAndServingQueues.findIndex(
      //     (x) =>
      //       x.Priority &&
      //       new Date(queue.RegistrationTime).getTime() <=
      //         new Date(x.RegistrationTime).getTime()
      //   );
      //   if (index === 0) {
      //     if (this.queues.newAndServingQueues[0].IsServing) {
      //       this.queues.newAndServingQueues.splice(1, 0, queue);
      //     } else {
      //       this.queues.newAndServingQueues.splice(0, 0, queue);
      //     }
      //   } else if (index > 0) {
      //     this.queues.newAndServingQueues.splice(index, 0, queue);
      //   } else {
      //     index = this.queues.newAndServingQueues.findIndex(
      //       (x) => !x.Priority && !x.IsServing
      //     );
      //     if (index === 0) {
      //       if (this.queues.newAndServingQueues[0].IsServing) {
      //         this.queues.newAndServingQueues.splice(1, 0, queue);
      //       } else {
      //         this.queues.newAndServingQueues.splice(0, 0, queue);
      //       }
      //     } else if (index > 0) {
      //       this.queues.newAndServingQueues.splice(index, 0, queue);
      //     } else {
      //       this.queues.newAndServingQueues.push(queue);
      //     }
      //   }
      // } else 
      const isExisitIndex = this.queues.newAndServingQueues.findIndex(
        (x) => x.QueueId == queue.QueueId
      );
      if (isExisitIndex >= 0) {
        const existingObj = this.queues.newAndServingQueues[isExisitIndex];
        if(existingObj.IsServing){
          return
        }
        this.queues.newAndServingQueues.splice(isExisitIndex, 1);
      }

      const missedQueueIndex = this.queues.missedQueues.findIndex(
        (x) => x.QueueId === queue.QueueId
      );
      if (missedQueueIndex >= 0) {
        //Remove the queue from it's current position
        this.queues.missedQueues.splice(missedQueueIndex, 1);
      }

      if (!queue.IsWalkIn) {
        if (
          this.queues.newAndServingQueues.some(
            (x) => !x.Priority && !x.IsWalkIn
          )
        ) {
          index = this.queues.newAndServingQueues.findIndex(
            (x) =>
              !x.Priority &&
              !x.IsWalkIn &&
              (new Date(queue.AppointmentTime).getTime() <
                new Date(x.AppointmentTime).getTime() ||
                (new Date(queue.AppointmentTime).getTime() ===
                  new Date(x.AppointmentTime).getTime() &&
                  new Date(queue.RegistrationTime).getTime() <
                    new Date(x.RegistrationTime).getTime()))
          );
          if (index === 0) {
            if (this.queues.newAndServingQueues[0].IsServing) {
              this.queues.newAndServingQueues.splice(1, 0, queue);
            } else {
              this.queues.newAndServingQueues.splice(0, 0, queue);
            }
          } else if (index > 0) {
            this.queues.newAndServingQueues.splice(index, 0, queue);
          } else {
            index = this.queues.newAndServingQueues.findIndex(
              (x) => x.IsWalkIn
            );
            if (index === 0) {
              if (this.queues.newAndServingQueues[0].IsServing) {
                this.queues.newAndServingQueues.splice(1, 0, queue);
              } else {
                this.queues.newAndServingQueues.splice(0, 0, queue);
              }
            } else if (index > 0) {
              this.queues.newAndServingQueues.splice(index, 0, queue);
            } else {
              this.queues.newAndServingQueues.push(queue);
            }
          }
        } else {
          index = this.queues.newAndServingQueues.findIndex(
            (x) => !x.Priority && x.IsWalkIn
          );
          if (index === 0) {
            if (this.queues.newAndServingQueues[0].IsServing) {
              this.queues.newAndServingQueues.splice(1, 0, queue);
            } else {
              this.queues.newAndServingQueues.splice(0, 0, queue);
            }
          } else if (index > 0) {
            this.queues.newAndServingQueues.splice(index, 0, queue);
          } else {
            this.queues.newAndServingQueues.push(queue);
          }
        }
      } else {
        index = this.queues.newAndServingQueues.findIndex(
          (x) =>
            (!x.Priority &&
              x.IsWalkIn &&
              queue.ServiceCounterPriority < x.ServiceCounterPriority) ||
            (queue.ServiceCounterPriority == x.ServiceCounterPriority &&
              new Date(queue.RegistrationTime).getTime() <
                new Date(x.RegistrationTime).getTime())
        );

        if (index === 0) {
          if (this.queues.newAndServingQueues[0].IsServing) {
            this.queues.newAndServingQueues.splice(1, 0, queue);
          } else {
            this.queues.newAndServingQueues.splice(0, 0, queue);
          }
        } else if (index > 0) {
          this.queues.newAndServingQueues.splice(index, 0, queue);
        } else {
          this.queues.newAndServingQueues.push(queue);
        }
      }
    }
    this.$store.commit("setWaitingQueuesCount", this.getWaitingQueuesCount());
  }
  setWaitingQList(data) {
    this.queues.newAndServingQueues.splice(
      0,
      this.queues.newAndServingQueues.length
    );
    let tempQueues = [];
    data.forEach((queue) => {
      tempQueues.push(queue);
      this.setBlinking(queue);
    });

    //const priorityQueues = tempQueues.filter((x) => x.Priority);
    const appointmentQueues = tempQueues.filter(
      (x) => !x.Priority && !x.IsWalkIn
    );
    const walkinQueues = tempQueues.filter((x) => !x.Priority && x.IsWalkIn);
    // priorityQueues.sort((a, b) => {
    //   if (a.QueueStatus === "Serving" || b.QueueStatus === "Serving") {
    //     return 0;
    //   }
    //   return (
    //     new Date(a.RegistrationTime).getTime() -
    //     new Date(b.RegistrationTime).getTime()
    //   );
    // });
    appointmentQueues.sort((a, b) => {
      if (a.QueueStatus === "Serving" || b.QueueStatus === "Serving") {
        return 0;
      }
      const result =
        new Date(a.AppointmentTime).getTime() -
        new Date(b.AppointmentTime).getTime();
      if (result === 0) {
        return (
          new Date(a.RegistrationTime).getTime() -
          new Date(b.RegistrationTime).getTime()
        );
      } else {
        return result;
      }
    });
    walkinQueues.sort((a, b) => {
      if (a.QueueStatus === "Serving" || b.QueueStatus === "Serving") {
        return 0;
      }
      if (a.ServiceCounterPriority === b.ServiceCounterPriority) {
        return (
          new Date(a.RegistrationTime).getTime() -
          new Date(b.RegistrationTime).getTime()
        );
      } else {
        return a.ServiceCounterPriority - b.ServiceCounterPriority;
      }
    });

    // priorityQueues.forEach((queue) => {
    //   this.queues.newAndServingQueues.push(queue);
    // });

    appointmentQueues.forEach((queue) => {
      this.queues.newAndServingQueues.push(queue);
    });

    walkinQueues.forEach((queue) => {
      this.queues.newAndServingQueues.push(queue);
    });

    //Check for serving queue in the list
    const servingQueue = this.queues.newAndServingQueues.find(
      (x) => x.IsServing
    );
    if (servingQueue) {
      this.setServingQueue(servingQueue);
    }
    this.$store.commit("setWaitingQueuesCount", this.getWaitingQueuesCount());
  }
  setStoredQList(data) {
    this.queues.storedQueues.splice(0, this.queues.storedQueues.length);
    data.forEach((queue) => {
      this.queues.storedQueues.push(queue);
    });
  }
  setMissedQList(data) {
    this.queues.missedQueues.splice(0, this.queues.missedQueues.length);
    data.forEach((queue) => {
      this.queues.missedQueues.push(queue);
    });
  }
  setEndedQList(data) {
    this.queues.endedQueues.splice(0, this.queues.endedQueues.length);
    data.forEach((queue) => {
      this.queues.endedQueues.push(queue);
    });
  }
  setTransferredQList(data) {
    this.queues.transferredQueues.splice(
      0,
      this.queues.transferredQueues.length
    );
    data.forEach((queue) => {
      this.queues.transferredQueues.push(queue);
    });
  }
  incrementWaitTime() {
    const queues = this.queues.newAndServingQueues.filter((x) => !x.IsServing);
    queues.forEach((q) => {
      q.WaitingTime += 1;
      this.setBlinking(q);
    });
  }
  getWaitingQueuesCount() {
    return this.queues.newAndServingQueues.filter((x) => !x.IsServing).length;
  }
  getServingQueue() {
    return this.queues.newAndServingQueues.find((x) => x.IsServing);
  }
  setBlinking(q) {
    if (q.WaitingTimeKPI <= q.WaitingTime) {
      q.IsBlink = true;
    } else {
      q.IsBlink = false;
    }
  }
}
