
import AppLayout from "@/components/AppLayout/AppLayout";
import { Hub } from "aws-amplify";
import { Auth } from "aws-amplify";
export default {
  components: {
    AppLayout,
  },
  beforeMount() {
    Hub.listen("auth", this.checkEvent);
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  mounted: async function () {
    await this.$store.commit("setServingQueue", null);
    await this.$queuesManager.setViewingQueue({});
    this.$store.commit("setloading", false);
    // this.$timer.start("registerForWebsocketEvents");
    // this.$websocketManager.initialize();
    //this.$router.push(`/active-queues/`);
    let duration = 1800000; //  this.$store.state.inActiveTimeDuration;
    this.onInactive(duration, () => {
      this.$store.commit("reset");
      this.$userSessionManager.logout().then((_) => {
        Auth.signOut().then((_) => {
          this.$router.push(`/`);
        });
      });
    });
  },
  methods: {
    checkEvent(data) {
      console.log("aws event", data.payload.event);
      switch (data.payload.event) {
        case "signIn":
          break;
      }
    },
    onInactive: function (ms, cb) {
      var wait = setTimeout(cb, ms);
      document.onmousemove =
        document.mousedown =
        document.mouseup =
        document.onkeydown =
        document.onkeyup =
        document.focus =
          function () {
            clearTimeout(wait);
            wait = setTimeout(cb, ms);
          };
    },
    async signOut() {
      await this.$userSessionManager.logout();
    },
  },
};
