import { Auth } from "aws-amplify";
export default ({ $axios, store }) => {
  $axios.onRequest((config) => {
    store.commit("loading", true);
    return Auth.currentSession()
      .then((session) => {
        // User is logged in. Set auth header on all requests
        config.headers.Authorization = "Bearer " + session.accessToken.jwtToken;
        return Promise.resolve(config);
      })
      .catch((error) => {
        // No logged-in user: don't set auth header
        return Promise.reject(error);
      });
  });
  $axios.onResponse((response) => {
    store.commit("loading", false);
    return response;
  });
  $axios.onError((error) => {
    store.commit('loading', false);
    if(error.response.status === 401){
        localStorage.clear();
        window.location.href =  window.location.origin;
    }
    console.log(err);
});
};
