
    export default {
        data: function () {
            return {
                expanded: false
            }
        },
        methods: {
            onClick: function (event) {
                this.expanded = !this.expanded;
                event.preventDefault();
            }
        }
    }
