
    import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
    import { required ,maxLength } from "vuelidate/lib/validators";

    export default {
        mixins: [DialogMixin],
        data: function () {
            return {
                display: false,
                input: '',
                validated: 2,
                model: {
                    queueId: null,
                    remark: '',
                    serviceId: null,
                },
            }
        },
        validations: {
            input: {
                maxLength: maxLength(300),
                required: required
            }
        },
        mounted: function () {
            let dataRecived = this.options.queueData;
            this.model.queueId = dataRecived.QueueId;
            this.model.serviceId = dataRecived.ServiceId;
        },
        methods: {
            onNextAction() {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return false;
                }
                if (this.input.length > 0) {                    
                    this.model.remark = this.input;
                    this.validated = 1;
                    if (this.model.serviceId) {
                        this.$axios.$post(`api/transfer-queues/`, this.model).then(data => {
                                this.validated = 2;
                                this.proceed(this.model);
                            });
                    }
                    else {
                        this.$axios.$post(`api/queue-remarks/`, this.model).then(data => {
                            this.validated = 2;
                                this.proceed(this.model);
                            });
                    }

                }
            },
            onClose: function () {
                this.close();
            }
        }
    }
