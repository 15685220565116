
	import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';
	import { required, maxLength } from 'vuelidate/lib/validators';
	
	export default {
		mixins: [DialogMixin],
		data: function () {
			return {
				resetKeyboard: true,
                helpTypes: [],
				help: {
					helpText: ""
				}
			}
		},
		validations: {
			help: {
				helpText: {
					required,
					maxLength: maxLength(5)
				}
			}
		},
		watch: {
		},
		mounted: function () {
			this.$refs.red.focused = true;
			this.loadHelpData();
		},
		methods: {
			
			loadHelpData: function () {
				// this.$axios.$get(`api/masters/helprequesttypes`)
				// 	.then(data => {
				// 		if (data) {
				// 			this.helpTypes = data;
				// 		}
				// 	});
			},
			
			onHelpAction: function () {
				this.$v.$touch()
				if (this.$v.$invalid) {
					return false;
				}
				if (this.help.helpText) {
                    this.proceed(this.help);
				}
			},
			onClose: function () {
				this.close();
			}
		}
	}

