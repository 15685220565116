
	import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';

	export default {
		mixins: [DialogMixin],
		data: function () {
			return {
				isCallEnable: false,
				toggleItems: [],
				selectedToggleItem: {},
				serviceQueueCallModel: {
					servingQueueId: null,
					serviceId: null
				}
			}
		},
		mounted: function () {
			let dataRecived = this.options.QueueData;
			if(dataRecived != null){
				this.serviceQueueCallModel.servingQueueId = dataRecived.QueueId;
			}
			this.loadToggleData();
		},
		methods: {
			loadToggleData: function () {
				this.$axios.$get(`api/toggle-queues/`,)
					.then(data => {
						if (data == null) {
							return;
						}
						this.toggleItems = data;
					});
			},
			selectService: function (service) {
					this.serviceQueueCallModel.serviceId = service.Id;
					this.isCallEnable = true;
			},
			onNextAction: function () {
				this.proceed(this.serviceQueueCallModel);
			},
			onClose: function () {
				this.close();
			}
		}
	}
