
    import AppSubmenu from './AppSubmenu';

    export default {
        props: {
            model: Array
        },
        methods: {
            onMenuItemClick: function (event) {
                this.$emit('menuitem-click', event);
            }
        },
        components: {
            'AppSubmenu': AppSubmenu
        }
    }
