import Vue from "vue";
import ToggleCall from "@/pages/toggle-call.vue";
import TransferQueue from "@/components/TransferQueuePopUp.vue";
import HelpFunction from "@/pages/help-function.vue";
import RemarkDialog from "@/components/RemarksPopup.vue";
import moment from "moment/moment.js";

Vue.filter("getTime",
	function (value) {
		if (value) {
			return moment(String(value)).format('h:mm A');
		}
		return value;
	});

Vue.filter("apptTime",
	function (value) {
		if (value) {
			return moment(String(value), ["HH:mm:ss"]).format("h:mm A");
		}
		return value;
	});

Vue.filter("minutes",
	function (value) {
		if (value) {
			return Math.floor(value / 60) + ":" + ("0" + (value % 60)).slice(-2);
		}
		return "0:00";
	});

Vue.filter("dateFormat",
	function (value) {
		if (value) {
			return moment(String(value)).format('DD/MM/YYYY');
		}
		return value;
	});

Vue.filter("formatStringTime",
    function (value) {
        if (value) {
			var timeLocal = moment.utc(value).local().format();
            var dtTimeArray = timeLocal.split('T');
            var time = dtTimeArray[1];
            var momentObj = moment(time, ["HH:mm:ss"]);
            return momentObj.format("h:mm A");
        }
        return value;
	});
	
Vue.filter("timeFormat",
function (value) {
	if (value) {
		return moment(String(value)).format('h:mm A');
	}
	return value;
});

    Vue.dialog.registerComponent("toggleCall", ToggleCall);
    Vue.dialog.registerComponent("transferQueue", TransferQueue);
    Vue.dialog.registerComponent("helpfunction", HelpFunction);
    //Vue.dialog.registerComponent("remarkDialog", RemarkDialog);
