
import { mapGetters } from "vuex";
import { Auth } from 'aws-amplify'
export default {
  data() {
    return {};
  },
  methods: {
    switchModule: function () {
    
      window.location.href = window.location.origin;
    },
    async signOut() {
      try {
        await this.$userSessionManager.logout();
        this.$store.commit("reset");
        await this.$userSessionManager.logout();
        await Auth.signOut().then((_) => {
        this.$router.push(`/`);
      });
        //this.$router.push(`/logout`);
      } catch (error) {}
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
  },
  computed: {
    user: function () {
      if (this.$store.state.userContext) {
        return this.$store.state.userContext.DisplayName;
      }
      return "";
    },
    module: function () {
      if (this.$store.state.userContext) {
        return this.$store.state.userContext.loggedOnParams.ModuleName;
      }
      return "";
    },
    allowToChooseParams: function () {
      if (this.$store.state.userContext) {
        return true;
      }
      return "";
    },
  },
};
