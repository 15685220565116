
import { mapState } from "vuex";
import AppTopBar from "../AppLayout/AppTopbar.vue";
import AppProfile from "../AppLayout/AppProfile.vue";
import AppMenu from "../AppLayout/AppMenu.vue";
import AppFooter from "../AppLayout/AppFooter.vue";
import { Auth } from 'aws-amplify'

export default {
  data: function () {
    return {
      layoutMode: "overlay",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      userSession: { DivisionId: "", ServiceCounterId: "" },
      menu: [
        { label: "Active Queues", to: "/active-queues" },
        { label: "Stored Queues", to: "/stored-queues" },
        { label: "Missed Queues ", to: "/missed-queues" },
        { label: "Ended Queues", to: "/ended-queues" },
        { label: "Search Queues", to: "/search-queues" },
        { label: "Service Dashboard", to: "/service-dashboard" },
      ],
      showLoader: false,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
    },
    isLoading() {
      this.showLoader = this.isLoading;
    },
  },
  methods: {
    async updateSession() {
      this.$axios.$get("user-contexts/").then((contextdata) => {
        if (contextdata && !contextdata.DivisionId) {
          this.$store.commit("reset");
          this.$userSessionManager.logout().then((_) => {
            Auth.signOut().then((_) => {
              this.$router.push(`/`);
            });
          });
        }
      });
      // await this.$store.dispatch("setUserContext", {});
      // await this.$store.dispatch("setUserContext", userContext);
    },
    onWrapperClick: function () {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle: function () {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          this.overlayMenuActive = !this.overlayMenuActive;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick: function () {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop: function () {
      return window.innerWidth > 1024;
    },
    isSidebarVisible: function () {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    ...mapState(["isLoading", "refCount"]),
    containerClass: function () {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
        },
      ];
    },
    sidebarClass: function () {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    logo: function () {
      return "./assets/layout/logo.png";
    },
  },
  
  // mounted: function () {
  //   this.updateSession();
  // },
  beforeUpdate: function () {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
