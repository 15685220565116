export default {
    data() {
        return {
            toastLife: 5000
        };
    },
    methods: {
        showSuccess(comName, msg) {
            this.$toast.add({
                severity: 'success',
                summary: comName,
                detail: msg,
                life: this.toastLife
            });
        },
        showError(comName, msg) {
            this.$toast.add({
                severity: 'error',
                summary: comName,
                detail: msg,
                life: this.toastLife
            });
        },
    }
};
