
    import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js';

    export default {
        mixins: [DialogMixin],
        data: function () {
            return {
				isCallEnable: false,
                toggleItems: [],
                searchQuery:null,
                selectedService: null
            }
        },
        mounted: function () {
            let dataRecived = this.options.transferQ;
            this.loadServicesData(dataRecived.queueId);
        },
        methods: {
            loadServicesData: function (queueId) {
				this.$axios.$get(`api/queue-services/${queueId}`).then(data => {
                        if (data == null) {
                            return;
                        }
                        this.toggleItems = data
                    });
            },
            selectService: function (service) {
                if (!service.isClosed) {
                    this.selectedService = service;
                    this.isCallEnable = true;
                }
                else {
                    this.selectedService = null;
                    this.isCallEnable = false;
                }
            },
            onNextAction: function () {
                //To pass back to parent
                if (this.selectedService) {
                    this.proceed(this.selectedService);
                } else {
                    this.notifyError("Please select Service");
                }
            },
            onClose: function () {
                this.close();
            }
        },
        computed: {
            servingQueue: function () {
                return this.$store.state.servingQueue;
            },
            resultQuery() {
                if (this.searchQuery) {
                    return this.toggleItems.filter((item) => {
                        return this.searchQuery.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
                    })
                } else {
                    return this.toggleItems;
                }
            }
        }
    }

