import { Auth } from "aws-amplify";

export class UserSessionManager {
  constructor(context, websocketManager) {
    this.$store = context.store;
    this.$axios = context.$axios;
    this.redirect = context.redirect;
    this.$websocketManager = websocketManager;
  }
  async logout() {
    try {
      await this.$axios.$get(`user-session-logout/`);
      await this.$store.dispatch("logout");
      if( this.$websocketManager.isOpenedSocket()){
      this.$websocketManager.dispose();
    }
      await Auth.signOut().then((userContext) => {
        this.$router.push(`/`);
      });
      
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
}
