import { EventBus } from "@/plugins/event-bus";
import { Auth } from "aws-amplify";

export class WebsocketManager {
  #socket;
  #subscriptionArray;
  #isReconnecting;
  #isOpened;
  constructor(queuesManager) {
    this.$queuesManager = queuesManager;
    this.#isReconnecting = false;
    this.#subscriptionArray = [];
    this.#isOpened = false;
  }

  async initialize() {
    const session = await Auth.currentSession();
    const token = session.accessToken.jwtToken;
    this.#socket = new WebSocket(`${process.env.websocketUrl}?token=${token}`);
    this.#socket.onopen = (event) => {
      this.#isOpened = true;
      if (this.#isReconnecting) {
        this.$queuesManager.onWebsocketReconnected();
        this.#isReconnecting = false;
      }
      for (const subscription of this.#subscriptionArray) {
        this.subscribe(
          subscription.eventName,
          subscription.entityId,
          subscription.entityType,
          subscription.needImmediateAttention
        );
      }
    };
    this.#socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const dataobj = JSON.parse(message.Data);
      if(message.EventName !== 'DashboardData'){
      this.$queuesManager.onQueueEvent(message.EventName, dataobj);
      }
      else
      {        
      EventBus.$emit(message.EventName, dataobj);
      }
    };
    this.#socket.onclose =async (event) => {
      this.#isOpened = false;
      if (event.wasClean && event.code === 1005) {
        this.#subscriptionArray = [];
      } else {
        this.#isReconnecting = true;
        await this.initialize();
      }
    };
  }

  subscribe(eventName, entityId, entityType, needImmediateAttention) {
    this.#socket.send(
      JSON.stringify({
        action: "subscribe",
        eventName,
        entityId,
        entityType,
        needImmediateAttention,
      })
    );
  }

  unsubscribe(eventName, entityId, entityType, needImmediateAttention) {
    this.#socket.send(
      JSON.stringify({
        action: "unsubscribe",
        eventName,
        entityId,
        entityType,
        needImmediateAttention,
      })
    );
  }

  registerFor(eventName, entityId, entityType, needImmediateAttention = false) {
    if (
      !this.#subscriptionArray.some(
        (x) =>
          x.eventName === eventName &&
          x.entityId === entityId &&
          x.entityType === entityType
      )
    ) {
      this.#subscriptionArray.push({
        eventName,
        entityId,
        entityType,
        needImmediateAttention,
      });
      if (this.#isOpened) {
        this.subscribe(eventName, entityId, entityType, needImmediateAttention);
      }
    }
  }
  unRegisterFor(
    eventName,
    entityId,
    entityType,
    needImmediateAttention = false
  ) {
    if (
      this.#subscriptionArray.some(
        (x) =>
          x.eventName === eventName &&
          x.entityId === entityId &&
          x.entityType === entityType
      )
    ) {
      if (this.#isOpened) {
        this.unsubscribe(
          eventName,
          entityId,
          entityType,
          needImmediateAttention
        );
        let index = this.#subscriptionArray
          .map((item) => item.eventName)
          .indexOf(eventName);
        this.#subscriptionArray.splice(index, 1);
      }
    }
  }
  dispose() {
    this.#socket.close();
  }
  isOpened() {
    return this.#isOpened;
  }
}
